.main {
  .mobileDescription {
    display: none
  }
}


@media only screen and (max-width: 991px) {
  .main {
    .mobileDescription {
      display: block;
    }
    .desktopDescription {
      display: none;
    }
  }
}
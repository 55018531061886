.paper_container {
  h4 {
    color: #c1bebe;
  }
  .transactions {
    cursor: pointer;
    transition: 0.3s;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    min-height: 35px;

    &:hover {
      background-color: rgb(243, 243, 243);
    }
    p {
      padding: 0;
      margin: 0;
      color: #8d8d8d;
    }
  }

  .list__pending {
    width: 100%;
    max-width: 360px;
    li {
      padding-left: 0;
    }

    .loaderSmall {
      margin: 0 10px 0 0;
      display: flex;
      align-items: center;
    }
  }

  .list__previous {
    height: 100%;
    width: 100%;
    max-width: 360px;
    li {
      padding-left: 0;
    }
  }
}

.container {
  padding: 48px 32px 5px;
}

.scrollableTabsMenuBox {
  margin: 0 0 40px;
}

.main {
  .top {
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 40px;
    justify-content: flex-start;
    margin-bottom: 45px;
  }
}

.active {
  font-weight: 700 !important;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    width: calc(100% + 8px);
    left: -4px;
    height: 2px;
    background-color: #000;
    z-index: 0;
    bottom: -4px;
  }
}

.empty {
  text-align: center;
  h6 {
    color: #A7A7A7;
    margin-top: 20vh;
    margin-bottom: 20vh;
  }
}

.mobileMenu {
    display: none;
    text-align: center;
}

.rightPageContent {
  background-color: #fff;
  padding: 30px;
  height: calc(100vh - 220px);
  overflow-y: auto;
}

.mobileAddress {
  display: none;
}
.desktopAddress {
  word-break: normal;
}

span[class*="wordBreakAll"] {
  word-break: break-all;
}

@media only screen and (max-width: 991px) {
  .rightPageContent {
    grid-row: 1;
    height: auto;
    min-height: unset;
    padding: 0;
    height: auto;
    overflow-y: visible;
  }
  .scrollableTabsMenuBox {
    margin: 20px;
  }
  .mobileMenu {
    display: block;
  }
  .main {
    .top {
      display: none;
    }
  }
  .container {
    padding: 15px 0;
    div[class*="rightPageContent"] {
      margin: 0 15px;
    }
  }
}

@media only screen and (max-width: 575px) {
  .mobileAddress {
    display: inline;
  }
  .desktopAddress {
    display: none;
  }
}

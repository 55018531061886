@import '../../constants.module.scss';

$size: 80px;
$mSize: 80px;

.container {
  z-index: 1;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  min-height: $size;
  background-color: #000;
  .headerMenu {
    display: flex;
    width: 100%;
  }
}

.header {
  display: 'flex';
  width: '100%';
  background-color: '#000';
  transition: '.5s';
  z-index: 2;
  position: 'fixed';
  top: 0;
}

.logoContainer {
  width: $size;
  height: $size;
  background-color: #001aff;
  align-self: flex-start;
  padding: 16px;
}

.inner {
  width: 100%;
  padding: 23px 0px 17px 35px;
  gap: 18px;
  .left {
    display: flex;
    align-items: center;
    column-gap: 1rem;
    .socialLinksDesktop {
      padding-left: 4px;
      margin-bottom: 5px;
      border-left: solid 1px #6b6b6b;
    }
    @media only screen and (max-width: $DESKTOP_WIDTH) {
      .socialLinksDesktop {
        display: none;
      }
    }
  }
  .top {
    display: flex;
    justify-content: space-between;
  }
  img {
    height: 32px;
  }
  .nav {
    .main {
      display: grid;
      white-space: nowrap;
      grid-auto-flow: column;
      grid-column-gap: 60px;
      a {
        font-weight: 500;
        color: #fff;
      }
    }

    @media only screen and (max-width: $DESKTOP_WIDTH) {
      .nav {
        display: none;
      }
    }
  }

  @media only screen and (max-width: $DESKTOP_WIDTH) {
    .container {
      height: $mSize;
      .headerMenu {
        width: 100%;
      }
    }
    .logoContainer {
      width: $mSize;
      height: $mSize;
      padding: 16px;
    }
    .inner {
      padding: 10px;
      flex-direction: row;
      align-items: start;
      justify-content: space-between;
      width: 100%;
      img {
        height: 2rem;
      }
    }
    .mobileMenu {
      display: block;
    }
  }

  button.buttonItemMenu {
    color: #fff;
    padding: 0px;
    font-size: 0.9rem;
    margin-top: 14px;
    height: auto;
    min-width: auto;
  }
}

.desktopMetamaskInfoMini {
  display: flex;
  align-items: start;
  margin-top: -3px
}

.mobileMetamaskInfoMini {
  display: none;
  margin-top: 24px;
  & > div {
    justify-content: center;
  }
}

@media only screen and (max-width: $MOBILE_WIDTH) {
  .desktopMetamaskInfoMini {
    display: none;
  }

  .mobileMetamaskInfoMini {
    display: block;
  }
}



.nav_column,
.nav_column__mobile {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
}

.nav_column__mobile {
  gap: 10px;
}

.mobileMenu {
  display: none;
  z-index: 3;
  .listMenu {
    background-color: #000;
  }

  ul.listMenu li a {
    color: #fff;
    width: 100%;
  }
  img.icon {
    width: 2rem;
    height: 2rem;
  }
}

.section {
  margin-bottom: 40px;
}

.title {
  color: #A7A7A7;
  text-transform: uppercase;
}

.loaderSmall {
  margin-top: 16px;
}
.dialogTitle {
  text-align: center;
}

.innerContainer {
  .priceInputField, .xyInputField {
    & > div {
      background-color: #E6E6E6;
      padding-left: 10px;
      padding-right: 10px;
      &::before {
        border-color:#001AFF;
      }
    }
  }
  .priceInputField {
    margin-bottom: 16px;
    width: 100%;
  }
  .xyInputField {
    width: 75px;
    input {
      text-align: center;
    }
  }
  .startAdornment {
    margin-right: 8px;
  }
  .endAdornment {
    margin-left: 8px;
    width: 55px;
    text-align: right;
    color: #A7A7A7;
  }
  .liquidityZoneInput {
    display: grid;
    grid-template-columns: 1fr 1fr 50px 1fr 1fr;
    gap: 10px;
    .separator {
      text-align: center;
    }
  }
  .buttons {
    display: grid;
    grid-auto-flow: column;
    margin: 35px 0 25px;
    justify-content: space-between;
  }
  .titleInput {
    margin-left: 10px;
    font-size: 0.75rem;
    margin-bottom: 5px;
    color: #000;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
  input[type="number"]:hover,
  input[type="number"]:focus {
    -moz-appearance: number-input;
  }
}


@media only screen and (max-width: 575px) {
  .innerContainer {
    .xyInputField {
      width: 100%;
    }
    .liquidityZoneInput {
      grid-template-columns: 1fr 1fr 5px 1fr 1fr;
      gap: 5px;
    }
  }
}

@media only screen and (max-width: 345px) {
  .innerContainer {
    .liquidityZoneInput {
      grid-template-columns: 1fr 1fr;
      & > div:nth-child(3) {
        grid-column: 1/3;
      }
    }
  }
}

@import '../../../constants.module.scss';


.main {
  display: flex;
  gap: 20px;
  flex-wrap: nowrap;
  align-content: center;
  margin-top: 8px;
  p {
    line-height: 1rem;
    font-size: 0.75rem;
    vertical-align: middle;
    white-space: nowrap;
    text-transform: uppercase;
    .indicator {
      display: inline-block;
      height: 0.4rem;
      width: 0.4rem;
      border-radius: 50%;
      margin-left: 5px;
    }
    .indicator.error {
      background-color: red;
    }
    .indicator.success {
      background-color: #05e005;
    }
  }
  .pending {
    display: flex;
    // border: 1px solid $COLOR_PALE_YELLOW;
    padding: 15px 8px;
    color: $COLOR_PALE_YELLOW;
    gap: 8px;

    align-items: center;
    p {
      border: none;
      margin: 0;
      padding: 0;
    }
    .loaderSmall {
      color: $COLOR_PALE_YELLOW;
    }
  }
  .address_container {
    display: flex;
    border: 1px solid #fff;
    div {
      padding: 15px 8px;
    }

    .balance {
      color: #fff;
      .counter {
        font-weight: 700;
      }
    }

    .address {
      cursor: pointer;
      background-color: #fff;
      color: #000;
      p {
        font-weight: 700;
        text-transform: initial;
      }
    }
  }
}

.main.mobile {
  justify-content: center;
  margin-top: 2rem;
}

@media only screen and (max-width: $MOBILE_WIDTH) {
  .main.desktop {
    display: none;
  }
  .main {
    flex-wrap: wrap;
  }
}

.main {
  display: grid;
  grid-template-columns: 1fr 400px;
  gap: 10px;
  margin-top: 26px;

  .topBar {
    display: grid;
    grid-template-columns: auto auto;
    gap: 5px;
    padding: 5px;
    width: 100%;
    height: 80px;
    background-color: #fff;
    align-items: center;

    .bastBtnGroup {
      margin-left: 40px;
      & > div {
        button {
          border: 2px solid #000;
          border-left: none;
          border-right: none;
          width: 125px;
          padding: 8px;
        }
        button:first-child {
          border-left: 2px solid #000;
        }
        button:last-child {
          border-right: 2px solid #000;
        }
      }
    }
  }

  .formControl {
    width: 200px;
    & > div {
      fieldset {
        border-radius: 0;
        border-color: black;
        border-width: 2px;
      }
    }
    .selectLiquidity {
      height: 40px;
      & > div {
        background: none;
      }
    }
  }
}

.tableContent {
  overflow-y: auto;
  height: calc(100vh - 301px);
}

@media only screen and (max-width: 991px) {
  .main {
    .topBar {
      justify-content: center;
      justify-items: center;
      grid-template-columns: auto auto;
      gap: 20px;
      .bastBtnGroup {
        margin-left: 0;
      }
    }
    grid-template-columns: 1fr;
  }

  .tableContent {
    overflow-y: unset;
    height: auto;
  }
}

@media only screen and (max-width: 500px) {
  .main {
    .topBar {
      .bastBtnGroup {
        & > div {
          button {
            width: 95px;
          }
        }
      }
      .formControl {
        width: 100px;
      }
    }
  }
}

$width: 285px;

@font-face {
  font-family: "Fontawesome";
  font-weight: 900;
  src: url("../../../Resources/Fonts/fa-solid-900.woff") format("truetype");
}

.faQuestionCircle {
  color: #fff;
  font-size: 3.75rem;
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &:before {
    font-family: "Fontawesome", sans-serif;
    content: "\f059";
  }
}

.main {
  width: $width;
  min-height: 135px;
  background-color: #fff;
  display: grid;
  grid-template-columns:  96px 1fr;
  & > div:nth-child(1) {
    text-align: center;
    padding: 20px;
    img {
      width: 100%;
      height: auto;
      max-width: 96px;
    }

  }
  & > div:nth-child(2) {
    padding: 15px;
    color: #000;
    h5 {
      font-weight: 700;
      margin-top: 10px;
      margin-bottom: 16px;
      font-size: 1.125rem;
    }
    p {
      font-weight: 700;
      font-size: .656rem;
    }  
  }
}

.main.disabled {
  & > div:nth-child(2) {
    color: #999;
  }
  img {
    opacity: .3;
  }
}

.main.add {
  background-color: #000;
  & > div:nth-child(2) {
    position: relative;
    display: flex;
    align-items: center;
    h5 {
      font-size: 1.5rem;
      color: #fff;
    }
    .backBlur {
      width: 175px;
      height: 135px;
      position: absolute;
      float: right;
      color: #cfcfcf;
      font-size: 1rem;
      filter: blur(5px);
      padding: 11px 0;
      h5 {
        font-size: 1.5rem;
        color: #cfcfcf
      }
      filter: blur(8px);
    }
  }
}

@media only screen and (max-width: $width) {
  .main {
    width: calc(100vw - 24px);
  }
  .faQuestionCircle {
    height: 128px;
  }
}

@media only screen and (max-width: 319px) {
  .main {
    & > div:nth-child(1) {
      min-height: 96px;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

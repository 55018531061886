.container {
  position: absolute;
  z-index: 1;
  &.active {
    background-color: #fff;
    height: 136px;
    width: 100%;
  }
}

.searchContainer {
  position: absolute;
  top: 16px;
  left: 32px;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(20px);
  z-index: 1;
  width: 560px;
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  &.active {
  background-color: #e6e6e6;
  }
  .buttons {
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 16px;
  }
  .input {
    width: 100%;
    margin-right: 16px;
    &:after, &:before, &:hover:before {
      border: none !important;
    }
  }
}

.resultContainer {
  margin-top: 85px;
  margin-left: 32px;
  .closeIcon {
    margin: -5px 0 0 20px;
  }
}

@media only screen and (max-width: 600px) {
  .container {
    width: 100%;
  }
  .searchContainer {
    top: 0;
    left: 0;
    width: 100%;
  }
}

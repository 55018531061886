.container {
  padding: 32px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: grid;
  grid-template-columns: 100px auto;
  grid-column-gap: 32px;
  .image {
    width: 100px;
    height: 100px;
    overflow: hidden;
    object-fit: cover;
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .top {
      display: grid;
      grid-auto-flow: column;
      grid-column-gap: 8px;
      justify-content: flex-start;
    }
    .tags {
      justify-content: flex-start;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      & > div {
        margin: 0 8px 8px 0;
      }
    }
    .button {
      margin-top: 16px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .container {
    .content {
      .tags {
        display: block;
        & > div {
          margin: 2px;
        }
      }
    }
  }
}

$paddingLeft: 50px;

.mainMobile {
  display: none;
}

.mainDesktop {
  border-bottom: 1px solid #A7A7A7;
  padding: 14px 0;
  & > div {
    display: grid;
    grid-template-columns: 1fr 1fr 1.4fr 2fr;
    // max-width: 800px;
    padding-left: $paddingLeft;
    column-gap: 20px;
  }

  & > div:nth-child(2) {
    margin-top: 10px;
    & > div:nth-child(1) {
      grid-column: 1 / 4;
      div {
        margin-bottom: 6px;
      }
      span {
        margin-right: 10px;
      }
      a {
        font-size: 0.75rem;
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .mainDesktop {
    display: none;
  }
  .mainMobile {
    display: block;
  }
}

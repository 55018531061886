.main {
  display: grid;
  grid-template-columns: 1fr 400px;
  gap: 10px;
  margin-top: 26px;
}

.tableContent {
  overflow-y: auto;
  height: calc(100vh - 220px);
}

@media only screen and (max-width: 991px) {
  .main {
    grid-template-columns: 1fr;
  }
  
  .tableContent {
    overflow-y: unset;
    height: auto;
  }
}

.popperContainer {
  z-index: 1;
}

.content {
  width: 310px;
  border: 1px solid #B3B3B3;
  background-color: rgba(255, 255, 255, 0.95);
  padding: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  z-index: 1;
  .row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }

  .closeIcon {
    text-align: right;
    margin: -12px -12px 0px 0;
    img {
      cursor: pointer;
    }
  }

  .image {
    text-align: center;
    img {
      height: 50px;
      width: auto;
    }
  }

  .uri {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .infoText {
    color: #F0C251;
  }
}

@font-face {
  font-family: "ZametkaParletter";
  font-weight: 600;
  src: url("../../../Resources/Fonts/zametka-parletter.ttf");
}

.tooltip {
  p,
  button {
    font-family: "ZametkaParletter", sans-serif;
    line-height: 1.5;
  }
}

.content {
  background-color: #f0f0f0;
  width: 400px;
  padding: 25px;
  border-radius: 57px 20px 20px 20px;
  text-align: right;
  span[class^="highlight-"] {
    color: #050096;
  }
  span[class="highlight-2"] {
    background-color: #d9d9d9;
    padding: 6px 6px 3px;
    border-radius: 13px;
  }
}

.tooltipFooter {
  display: flex;
  column-gap: 1rem;
  justify-content: space-between;
  margin-top: 2rem;
  margin-bottom: 2rem;
  button {
    padding-top: 11px;
    color: #050096;
    background-color: #d9d9d9;
    width: auto;
    font-size: 18px;
    border-radius: 18px;
    &:hover {
      background-color: #a5a5a5;
    }
  }
}

.controlRight {
  display: flex;
  column-gap: 1rem;
}

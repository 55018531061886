@font-face {
  font-family: 'ZametkaParletter';
  src: url('../../../Resources/Fonts/zametka-parletter.ttf') format('truetype');
}

.main {
  p,
  button {
    font-family: 'ZametkaParletter', sans-serif;
    line-height: 1.5;
  }
  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 40px 16px;
  }
  .content {
    background-color: #f0f0f0;
    padding: 24px;
    border-radius: 32px;
    text-align: right;
    span[class^='highlight-'] {
      color: #050096;
    }
    span[class='highlight-2'] {
      background-color: #d9d9d9;
      padding: 6px 6px 3px;
      border-radius: 13px;
    }
  }
  .triangle {
    content: ' ';
    position: relative;
    width: 0;
    height: 0;
    left: 60px;
    top: -30px;
    border-left: 34px solid transparent;
    border-right: 34px solid transparent;
    border-top: 60px solid #f0f0f0;
    transform: rotate(6deg);
  }
  .buttons {
    display: grid;
    grid-template-columns: 1fr;
  }
  .start {
    text-align: right;
  }

  @media (max-height: 590px) {
    .buttons {
      grid-template-columns: 1fr 1fr;
    }
    .wrapper {
      justify-content: center;
    }
  }
}

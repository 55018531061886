.main {
  display: grid;
  grid-template-columns: 1fr 400px;
  gap: 10px;
  margin-top: 26px;

  .left {
    position: relative;
  }

  .statisticsBarMobile {
    display: none;
  }

  .statisticsBar {
    position: absolute;
    margin-left: 10px;
    margin-top: 10px;
    display: grid;
    column-gap: 10px;
    grid-template-columns: 1fr 1fr auto;
    min-width: 500px;
    height: 80px;
    background-color: #fff;
    align-items: center;
    padding: 0 50px;
  }

  .LiquidityMap {
    background-color: gray;
    min-height: calc(100vh - 220px);
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

.bastBtnGroup {
  margin-bottom: 10px;
  & > div {
    button {
      border: 2px solid #000;
      border-left: none;
      border-right: none;
    }
    button:first-child {
      border-left: 2px solid #000;
    }
    button:last-child {
      border-right: 2px solid #000;
    }
  }
}

.mapImg {
  margin: 15px 0;
  display: block;
  height: 200px;
  opacity: 0.25;
}


@media only screen and (max-width: 991px) {
  .main {
    grid-template-columns: 1fr;
    .statisticsBar {
      display: none
    }
    .statisticsBarMobile {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      padding: 20px 10px;
      margin: 10px 0 20px 0;
    }
    .LiquidityMap {
      height: 80vh;
    }
  }
}

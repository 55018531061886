span.main {
  margin-top: 1rem;
  max-width: 700px;
  height: 79px;
}

@media only screen and (max-width: 991px) {
  span.main {
    max-width: 100%;
    width: 100%;
    height: 113px;
  }
}
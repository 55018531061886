.container {
  max-width: 100vw;
}

div[class*="MuiAccordion-root"].container {
  border-radius: 0;
  box-shadow: none;
  border-bottom: 1px solid #A7A7A7;
  background: none;
}

.container div[class*="Mui-disabled"] {
  opacity: 1;
  div[class*="MuiAccordionSummary-expandIcon"] {
    opacity: 0;
  }
}

.summary {
  div[class*="MuiAccordionSummary-content"] {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 45px;
  }
  > div:nth-child(1) {
    > div:nth-child(2) {
      > div, > div > div {
        justify-content: flex-end;
      }
      text-align: right;
      p, h6 {
        margin-right: 0;
      }
    }
  }
}

div.details {
  display: grid;
  column-gap: 10px;
  grid-template-columns: 1fr 1fr;
  > div:nth-child(2) {
    text-align: right;
  }
  div {
    margin-bottom: 6px;
  }
  span {
    margin-right: 10px;
  }
  button span {
    margin: 0;
  }
  a {
    font-size: 0.75rem;
    word-break: break-all;
  }
}

@media only screen and (max-width: 500px) {
  div.details {
    grid-template-columns: auto auto;
  }
  .summary {
    div[class*="MuiAccordionSummary-content"] {
      grid-template-columns: auto auto;
      justify-content: space-between;
    }
  }
}
@font-face {
  font-family: "Rawline";
  font-weight: 400;
  src: url("./Resources/Fonts/rawline-400.ttf") format("truetype");
}

@font-face {
  font-family: "Rawline";
  font-weight: 500;
  src: url("./Resources/Fonts/rawline-500.ttf") format("truetype");
}

@font-face {
  font-family: "Rawline";
  font-weight: 700;
  src: url("./Resources/Fonts/rawline-700.ttf") format("truetype");
}

@font-face {
  font-family: "Rawline";
  font-weight: 900;
  src: url("./Resources/Fonts/rawline-900.ttf") format("truetype");
}


* {
  box-sizing: border-box;
}

html, body {
  height: 100%;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}


body {
  margin: 0;
  font-family: "Rawline", sans-serif;
  background: #E5E5E5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}


input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

:global {
  #root {
    height: 100%;
  }
  button.MuiButton-contained,
  button.MuiButton-contained:hover {
    box-shadow: none;
  }
}

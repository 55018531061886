.dialogTitle {
  text-align: center;
}
li.itemSelect {
  display: flex;
  justify-content: space-between;
  height: 62px;
}
.itemImg {
  height: 50px;
  margin-left: 20px;
}
.previewImage {
  margin-top: 20px;
  text-align: center;
  img {
    height: 150px;
  }
}

.innerContainer {
  & > div {
    width: 270px;
  }
   .buttons {
    display: grid;
    grid-auto-flow: column;
    margin: 35px 0 25px;
    column-gap: 10px;
    justify-content: space-between;
  }

  div[class*="MuiFilledInput-input"] {
    padding: 10px 12px 10px;
    .itemImg {
      display: none;
    }
  }
}

@media only screen and (max-width: 575px) {
  .innerContainer {
    & > div {
      width: 100%;
    }
  }
}

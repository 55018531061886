.withTooltip {
    label {
      z-index: 1;
    }
  }
  
  .multiLayerBox {
    display: grid;
    grid-template-columns: 264px;
    row-gap: 3rem;
    column-gap: 2rem;
  }
  
  .controlMultiLayerView {
    span:last-child {
      text-transform: uppercase;
      color: rgba(0,0,0,0.7);
    }
    label[class*="disabled"] {
      span:last-child {
        color: #e5e5e5
      }
    }
  }
  
  .layerCaption {
    text-transform: uppercase;
  }
$heightHeader: 100px;
$heightHeaderMobile: 80px;

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 0 0;
  height: calc(100% - 100px);
}

.list {
  margin-top: 32px;
  overflow-y: auto;
  flex-grow: 1;
  &::-webkit-scrollbar {
    display: none;
  }
}

.loader {
  text-align: center;
  margin: 16px 0 !important;
}

.header {
  font-weight: bold !important;
}

@media only screen and (max-width: 600px) {
  .container {
    height: calc(100% - #{$heightHeaderMobile});
  }
  .list {
    width: 100%;
    overflow-x: auto;
    & > div {
      min-width: fit-content;
    }
  }
}

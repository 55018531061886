$height: 80px;

@keyframes slidin {
  from {
    left: -600px;
  }
  to {
    left: 0;
  }
}

.container {
  position: fixed;
  left: 0;
  bottom: 0;
  height: $height;
  display: grid;
  grid-template-columns: 80px 300px 120px;
  align-items: center;
  background-color: #001AFF;
  z-index: 9999;
  h5 {
    font-size: 1rem;
  }
  .image {
    background-color: #fff;
    height: 100%;
    overflow: hidden;
    text-align: center;
    padding: 11px;
    img {
      height: 60px;
      width: auto;
    }
  }


  .text {
    background-color: #001AFF;
    font-weight: 700;
    line-height: 1.3;
    max-width: 300px;
    padding: 0 20px;
  }

  .control {
    background-color: #001AFF;
  }

  &.show {
    left: -600px;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    animation-name: slidin;
    animation-duration: 1s;
    animation-delay: 3s;
  }
}

@media only screen and (max-width: 575px) {
  .container {
    grid-template-columns: 1fr 120px;
    .image {
      display: none;
    }
  }
}

@media only screen and (max-width: 380px) {
  .container {
    .text {
      font-size: .8rem;
    }
  }
}

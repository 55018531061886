$paddingLeft: 50px;

.mainMobile {
  display: none;
}

.mainDesktop {
  border-bottom: 1px solid #A7A7A7;
  padding: 14px 0;
  & > div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    // max-width: 800px;
    padding-left: $paddingLeft;
    column-gap: 20px;
    row-gap: 10px;
    & > div {
      & > span {
        margin-right: 10px;
      }
      a:not(a[class*="valueLink"]) {
        font-size: 0.75rem;
      }
    }
  }
}

.expanded, .noExpanded {
  text-align: right;
  padding-top: 5px;
  .topRight {
    margin-top: 7px;
    & > span {
      margin-left: 10px;
    }
  }
}

.expanded {
  display: none;
}

div[class*="Mui-expanded"] {
  .noExpanded {
    display: none;
  }
  .expanded {
    display: inline;
  }
}

.detailsFirst {
  grid-column: 1/3;
  margin-top: -18px;
}

div[class*="details"] {
  & > div.detailSecond {
    text-align: left;
  }
}

.detailThird {
  text-align: right;
  button {
    margin-right: 36px;
    margin-top: 10px;
  }
}


@media only screen and (max-width: 991px) {
  .mainDesktop {
    display: none;
  }
  .mainMobile {
    display: block;
  }
}

.title {
  font-weight: 700 !important;
}
.titleUp {
  color: #A7A7A7;
  text-transform: uppercase;
}

.expand {
  display: grid;
  grid-auto-flow: column;
  justify-content: flex-end;
  align-items: center;
  grid-column-gap: 8px;
  .arrow {
    margin-top: 5px;
    transform: rotate(180deg);
    &.down {
      transform: rotate(0deg);
    }
  }
}

.summary {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

div.summaryDaily {
  display: grid;
  grid-template-columns: 10px 1fr 1fr 160px;
  & > h6 {
    grid-column: 1/4;
  }
  & > div {
    justify-content: start;
  }
}

.items {
  width: 100%;
  display: grid;
  grid-auto-flow: row;

  .item {
    display: grid;
    grid-template-columns: 10px 1fr auto auto;
    grid-column-gap: 16px;
    align-items: center;
    justify-content: flex-start;
    padding: 8px 16px;
    cursor: pointer;
    &.active {
      background-color: #001AFF;
      * {
        color: #fff !important;
      }
    }
  }

  .itemDaily {
    &.boldType p {
      font-weight: 600;
    }
    grid-template-columns: 10px 1fr 1fr 145px;
  }
  

  .image {
    max-width: 10px;
    max-height: 10px;
    background-color: #000000;
  }

  .empty {
    width: 10px;
    height: 10px;
    background-color: #000;
  }

  .color {
    color: rgba(0, 0, 0, 0.3);
  }
}

@media only screen and (max-width: 576px) {
  .items.itemsDaily {
    .item:not(:first-child) {
      padding: 8px 0;
      & > *:nth-child(1) {
        display: none;
      }
      & > *:nth-child(2) {
        grid-column: 1/3;
      }
    }
    .item.itemDaily {
      padding: 8px 0;
    }
  }
  div.summaryDaily {
    grid-template-columns: 10px 1fr 1fr 145px;
  }
}

@media only screen and (max-width: 400px) {
  .items .itemDaily {
    grid-template-columns: 10px 1fr 1fr 90px;
  }
  div.summaryDaily {
    & > div {
      justify-content: end;
    }
  }
}


$heightHeader: 80px;
$heightHeaderMobile: 80px;

.container {
  // background: rgba(230, 230, 230, 0.9);
  background: #e5e5e5;
  position: relative;
  height: calc(100% - 100px);
  width: 100%;
  &.containerHidden {
    opacity: 0;
  }
}
.wallPreview {
  background: rgba(180, 180, 180, 0.9);
  position: absolute;
  top: 12px;
  right: 12px;
  border: 1px solid #001aff;
  &.containerHidden {
    opacity: 0;
  }
  @media only screen and (max-width: 850px) {
    top: 76px;
  }

  @media only screen and (max-width: 600px) {
    top: 57px;
  }
}

.infoArea {
  white-space: nowrap;
}
button.btnDots {
  justify-content: flex-start;
  width: 100%;
}
.zoomControls {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100px;
  height: 100px;
  background: #fff;
  display: grid;
  z-index: 1;
  grid-template-rows: 1fr 1fr;
  .icon {
    max-width: 30px;
  }
}

.infoContainer {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  border: 1px solid #b3b3b3;
  background-color: rgba(255, 255, 255, 0.95);
  // backdrop-filter: blur(20px);
  padding: 32px;
  // max-width: 400px;
  // min-width: 300px;
  width: 373px;
  max-height: 330px;
  overflow-y: auto;
  &.setting {
    min-height: 300px;
  }
  .titleAreaCluster {
    display: flex;
  }
  .top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .link {
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 32px;
    display: block;
  }
  .tags {
    // overflow: auto;
    // max-height: 110px;
    // display: grid;
    // grid-auto-flow: column;
    // grid-gap: 8px;
    // justify-content: flex-start;
    margin-bottom: 16px;
    & > div {
      margin: 1px;
    }
  }
  .report {
    display: block;
    margin-top: 16px;
  }
  .coords {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 8px;
    justify-content: flex-start;
    margin-bottom: 24px;
    &.owned {
      margin-bottom: 16px;
    }
    > div {
      display: grid;
      grid-auto-flow: column;
      grid-gap: 4px;
    }
  }
  .buttons {
    display: grid;
    grid-auto-flow: row;
    grid-gap: 16px;
  }
}

.loader {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100% - 100px);
  z-index: 1000;
}

.clusterInfo {
  position: absolute;
  bottom: 0;
  left: 0;
  width: calc(100% - 100px);
  height: 170px;
  background-color: #f2f2f2;
  padding: 24px 48px;
  z-index: 1;
  .closeIcon {
    position: absolute;
    right: 40px;
    top: 32px;
  }
}

.closeButton {
  position: absolute !important;
  right: 32px;
  top: 32px;
}

button.closeButtonSticky {
  position: sticky;
  float: right;
  top: 0;
  img {
    height: 22px;
    width: 22px;
  }
}

.clusterBuyButton {
  margin-top: 18px !important;
}

.root {
  position: relative;
  height: calc(100% - #{$heightHeader});
}

.rentButton {
  margin-top: 16px !important;
}

.dots {
  display: grid;
  grid-auto-flow: column;
  column-gap: 5px;
  align-items: center;
  padding-top: 3px;
  margin-left: 15px;
  margin-bottom: 0.35em;
  .dot {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #000;
  }
}
.dots.notMine {
  .dot {
    background-color: #7f7f7f;
  }
}

.user {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: baseline;
  & > a {
    margin-right: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  & > p {
    display: inline;
  }
}

@media only screen and (max-width: 600px) {
  .root {
    height: calc(100% - #{$heightHeaderMobile});
  }
  .FPSStats {
    // display: none;
    & > div {
      inset: 0px auto auto calc(100% - 76px);
    }
  }
  .zoomControls {
    align-items: start;
    justify-items: center;
    height: 90px;
    width: 45px;
    bottom: 0;
    button {
      display: inline-block;
      min-width: 42px;
      width: 42px;
    }
    button:first-child img {
      padding-top: 5px;
    }
  }
  .clusterInfo {
    h6 {
      font-size: 1rem;
      line-height: 1.1;
    }
    width: calc(100% - 45px);
    padding: 15px;
    .closeIcon {
      position: absolute;
      right: 7px;
      top: 7px;
    }
  }
}

.banned {
  display: flex;
  margin: 0 0 10px 0;
  align-items: center;
  a {
    cursor: pointer;
  }
  img {
    height: 23px;
    margin: 0 10px 0 0;
  }
}

.arlinkBox {
  cursor: pointer;
  img {
    height: 22px;
  }
  display: flex;
  align-items: stretch;
  background-color: rgba(11, 46, 251, 0.2);
  gap: 0.7rem;
  padding: 0.5rem 0.8rem;
  margin-bottom: 0.5rem;
}

$width: 991px;

.main {
  margin-top: 60px;
  padding: 15px 15px 100px 15px;
  max-width: $width;
  margin-right: auto;
  margin-left: auto;
  margin-top: 16px;
  h1.titlePage {
    font-size: 2.14rem;
    text-align: center;
    margin: 10px auto 45px;
    color: #000;
    font-weight: 700;
  }
  .itemsSection {
    display: grid;
    grid-template-columns: repeat(3, 300px);
    justify-content: center;
    row-gap: 20px;
    column-gap: 20px;
  }
  @media only screen and (max-width: $width) {
    .itemsSection {
      grid-template-columns: repeat(2, 300px);
    }
  }
  @media only screen and (max-width: 650px) {
    .itemsSection {
      grid-template-columns: 300px;
    }
  }
}


.popperContainer {
  z-index: 1;
  position: relative;
  margin-bottom: 20px;
}

.innerContainer {
  .xyInputField {
    background-color: #E6E6E6;
    & > div {
      padding-left: 10px;
      padding-right: 10px;
      &::before {
        border-color:#001AFF;
      }
    }
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
  input[type="number"]:hover,
  input[type="number"]:focus {
    -moz-appearance: number-input;
  }
}

.buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  margin: 25px 0 0 0;
  justify-content: space-between;
}

.inputs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  input {
    text-align: center;
  }
}

.main {
  width: 240px;
  background-color: #fff;
  padding: 25px 15px;
  button {
    width: 75px;
  }
}
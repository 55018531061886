.balance__list {
  li {
    padding-left: 0px;
    span: {
    }
    & .text {
      p {
        font-size: 12px;
        color: #c1bebe;
      }
    }
    img {
      margin: 0;
      height: fit-content;
    }
  }
}

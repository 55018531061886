.container {
  border-radius: 0 !important;
  padding: 10px 10px 30px 10px;

  &:before {
    display: none;
  }

  & > div:nth-child(1) {
    bottom: -5px;
    position: absolute;
    z-index: 10;
    left: calc(50% - 40px);
    > div {
      margin: 0;
    }
  }

  & > div:nth-child(2) {
    min-height: 89px !important;
    visibility: unset;
  }
}

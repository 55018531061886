.placeholder {
  background-color: #F6F6F6;
  border: 1px solid #DDDDDD;
  width: 176px;
  height: 176px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
  cursor: pointer;
  .placeholderLabel {
    text-transform: uppercase;
    font-weight: bold;
    user-select: none;
  }
}

.imageContainer {
  max-width: 360px;
  .image {
    height: 264px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 12px;
    cursor: pointer;
    background-color: #F6F6F6;
    border: 1px solid #DDDDDD;
    overflow: hidden;
    position: relative;
    img {
      max-width: 180px;
      max-height: 220px;
      object-fit: contain;
    }
  }
  &.disabled {
      opacity: 0.5;
      .image {
        cursor: auto;
      }
  }
  .controlBottom {
    padding: 14px 0 20px;
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px solid #DDDDDD;
    .delete {
      color: #E7002A !important;
    }
  }
}



.uploadInput {
  display: none;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
};
.modalContainer {
  background-color: #fff;
  width: 80%;
  max-width: 1100px;
  outline: none;
  .top {
    width: 100%;
    height: 136px;
    background-color: #001AFF;
    display: flex;
    align-items: center;
    padding: 0 72px;
  }
  .title {
    font-weight: bold;
  }
  .bottom {
    width: 100%;
    height: 136px;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      &.leftDark {
        background-color: transparent;
        grid-template-columns: 400px auto;
        .labels {
          color: #fff !important;
          display: flex;
          flex-direction: column;
        }
      }
      background-color: #f2f2f2;
      display: grid;
      grid-template-columns: 300px auto;
      flex: 1;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      padding: 48px 64px;
      max-width: 650px;
    }
    .slider {
      position: relative;
      width: 170px;
      .sliderInfo {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      .sliderPrice {
        position: absolute;
        bottom: -15px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        left: 0;
        right: 0;
      }
      .errorMessage {
        white-space: nowrap;
      }
    }
    .buttons {
      display: grid;
      grid-auto-flow: column;
      grid-column-gap: 40px;
      padding: 48px 64px;
    }
    .cancel {
      color: #fff;
      text-transform: uppercase;
    }
  }
  .editor {
    width: 100%;
    height: 400px;
    background: #E5E5E5;
    position: relative;
  }
}

.disabled {
  opacity: 0.5;
  div {
    cursor: auto;
  }
}

.preloader {
  position: absolute;
  left: calc(50% - 24px);
  top: calc(50% - 24px);
  z-index: 0;
}

@media only screen and (max-width: 600px) {
  .modalContainer {
    width: 100%;
    .bottom {
      .buttons {
        padding: 20px;
        justify-items: center;
        grid-template-columns: 1fr 1fr;
        width: 100%;
      }
    }
    .top, .bottom {
      height: 80px;
    }
    .editor {
      height: calc(100% - 160px);
    }
  }
  .modal {
    align-items: stretch;
  }
  .imageContainer {
    width: auto;
  }
}

.container {
  padding: 48px 32px;
  .section {
    margin-bottom: 24px;
  }
  .title {
    color: #A7A7A7;
    text-transform: uppercase;
  }
  .items {
    display: grid;
    grid-template-columns: repeat(auto-fill, 160px);
    grid-gap: 24px;
    margin-top: 16px;
    .item {
      display: grid;
      grid-auto-flow: row;
      grid-row-gap: 16px;
    }
  }
  .top {
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 40px;
    justify-content: flex-start;
    margin-bottom: 45px;
  }
}

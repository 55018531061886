@font-face {
  font-family: 'ZametkaParletter';
  font-weight: 600;
  src: url('../../../Resources/Fonts/zametka-parletter.ttf');
}

.tooltip {
  p,
  button {
    font-family: "ZametkaParletter", sans-serif;
    line-height: 1.5;
  }
  height: 100dvh;
  padding: 40px 16px;
  display: grid;
  grid-template-columns: 1fr;
  overflow: auto;

  img, video {
    max-height: 160px;
    display: block;
    margin: auto;
    max-width: 100%;
    width: auto;
  }
}

@media only screen and (orientation: landscape) {
  .tooltip {
    padding: 16px;
    grid-template-columns: 1fr 294px;
  }
}

.content {
  background-color: #f0f0f0;
  padding: 24px;
  border-radius: 32px;
  text-align: right;
  span[class^='highlight-'] {
    color: #050096;
  }
  span[class='highlight-2'] {
    background-color: #d9d9d9;
    padding: 6px 6px 3px;
    border-radius: 13px;
  }
}

.triangle {
  content: ' ';
  position: relative;
  width: 0;
  height: 0;
  left: 60px;
  top: -30px;
  border-left: 34px solid transparent;
  border-right: 34px solid transparent;
  border-top: 60px solid #f0f0f0;
  transform: rotate(6deg);
  z-index: -1;
}

.tooltipFooter {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  row-gap: 2.5rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  button {
    padding-top: 11px;
    color: #050096;
    background-color: #d9d9d9;
    width: auto;
    font-size: 18px;
    border-radius: 18px;
  }
  button:hover { background-color: #d9d9d9; }
}

.controlTop {
  display: flex;
  justify-content: end;
}

.controlButtom {
  display: flex;
  justify-content: end;
  button {
    font-size: 23px;
    padding: 16px 32px;
  }
}

.cluster {
  position: relative;
  cursor: pointer;
  user-select: none;
  .image {
    object-fit: cover;
    height: 100px;
    width: 100%;
  }
  .inner {
    overflow: hidden;
    width: 160px;
  }
  .blurImage {
    object-fit: cover;
    opacity: 0.5;
    filter: blur(40px);
    position: absolute;
    top: 0;
    width: 160px;
    height: 100px;
  }
  .noImage {
    width: 100%;
    height: 100px;
    color: #A7A7A7;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
  }
  .clusterTitle {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 500;
  }
  .bottom {
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(20px);
  }
  .content {
    padding: 8px 8px 12px 16px;
  }
  .clusterActive {
    background: rgba(0, 26, 255, 0.9);
    color: #fff !important;
  }
  .price {
    font-weight: bold !important;
    margin-top: 4px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

@media only screen and (max-width: 600px) {
  .cluster {
    .inner {
      width: auto;
    }
    .blurImage {
      width: auto;
    }
  }
}

.button {
  max-height: 32px;
  &.selected {
    border-color: #000000;
  }
  &.active {
    background-color: #001AFF;
    border-color: #001AFF;
    color: #fff;
  }
  p {
    font-weight: 700;
  }
}

.innerContainer {
  padding: 12px 16px;
  display: grid;
  grid-auto-flow: row;
  .label {
    span {
      font-weight: 700;
    }
  }
}

.main {
  display: grid;
  grid-template-columns: 1fr 400px;
  gap: 10px;
  margin-top: 26px;

  .pageBarMobile {
    display: none;
  }

  .pageBar {
    margin-bottom: 20px;
    display: grid;
    column-gap: 50px;
    grid-template-columns: 1fr 1fr 1fr;
    min-width: 500px;
    max-width: 700px;
    background-color: #fff;
    align-items: center;
    padding: 12px 50px;
    align-items: start;
  }
}

.bastBtnGroup {
  margin-bottom: 10px;
  & > div {
    button {
      border: 2px solid #000;
      border-left: none;
      border-right: none;
      width: 110px;
    }
    button:first-child {
      border-left: 2px solid #000;
    }
    button:last-child {
      border-right: 2px solid #000;
    }
  }
}

.mapImg {
  margin: 15px 0;
  display: block;
  height: 200px;
  opacity: 0.25;
}


@media only screen and (max-width: 991px) {
  .main {
    grid-template-columns: 1fr;
    .pageBar {
      display: none
    }
    .pageBarMobile {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      padding: 20px 10px 15px;
      margin: 10px 0 20px 0;
    }
    .LiquidityMap {
      height: 80vh;
    }
  }
}

@media only screen and (max-width: 350px) {
  .bastBtnGroup {
    margin-bottom: 10px;
    & > div {
      button {
        width: 100px;
      }
    }
  }
}

.button {
  max-height: 32px;
  &.selected {
    border-color: #000000;
  }
  &.active {
    background-color: #001AFF;
    border-color: #001AFF;
    color: #fff;
  }
  p {
    font-weight: 700;
  }
}

.innerContainer {
  padding: 24px 16px;
  max-width: 360px;
  .inputs {
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 16px;
    margin-bottom: 24px;
  }
  .startAdornment {
    margin-right: 8px;
  }
  .endAdornment {
    margin-left: 8px;
  }
  .buttons {
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 8px;
    justify-content: flex-end;
  }
}

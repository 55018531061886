.main {
  display: grid;
  grid-template-columns: 1fr 400px;
  gap: 10px;
  margin-top: 26px;

  .topBar {
    margin-bottom: 20px;
  }

  .formControl {
    min-width: 150px;
    & > div {
      fieldset {
        border-radius: 0;
        border-color: black;
        border-width: 2px;
      }
    }
    .selectLiquidity {
      height: 40px;
      & > div {
        background: none;
      }
    }
  }
}

.tableContent {
  overflow-y: auto;
  height: calc(100vh - 280px);
}

@media only screen and (max-width: 991px) {
  .main {
    grid-template-columns: 1fr;
    .topBar {
      text-align: center;
      margin-top: 20px;
    }
  }

  .tableContent {
    overflow-y: unset;
    height: auto;
  }
}

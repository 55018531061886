.buttons {
  margin: 1rem;
  button.addNetworkBtn {
    width: 195px;
    img.iconBtn {
      height: 24px;
      margin-top: 0;
      width: auto;
    }
    .btnText {
      display: inline-block;
      width: 100%;
    }
  }
}

.titleUp {
  color: #A7A7A7;
  text-transform: uppercase;
}
div.leftAlign {
  text-align: left;
  padding: 28px 28px 8px 28px;
  p.addMargin {
    margin-top: 8px;
    margin-bottom: 24px;
  }
  button {
    margin-bottom: 24px;
  }
}

div.success {
  img {
    margin-right: 1rem;
  }
}

@media only screen and (max-width: 500px) {
  .buttons {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  }
  .dialogV2 .buttons {
    flex-direction: row;
  }
  .dialogV3 .buttons {
    button {
      width: 100%;
    }
  }
}

.dialogV2 {
  text-align: center;
  & > div > div {
    max-width: 340px;
  }
  .buttons {
    justify-content: space-evenly;
  }
  .title {
    text-align: left;
  }
  .content {
    margin: 30px 20px;
  }
}

img.closeIcon {
  cursor: pointer;
  height: 18px;
  width: 18px;
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
}

.main {
  overflow: hidden;
  .inner_container {
    background-color: #fff;
    width: auto;
    max-width: 800px;
    margin: auto;
    min-height: 500px;
    padding: 30px;
    p {
      padding: 0;
      margin: 0;
    }

    .address {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h6 {
        font-weight: bold;
        line-height: 100%;
        align-self: center;
        text-justify: distribute;
      }
      img {
        margin: 0;
        width: fit-content;
      }
    }

    .actions {
      p {
        margin-left: 5px;
      }
    }

    .balance {
      margin-top: 20px;

      .balance__list {
        li {
          padding-left: 0px;
          span: {
          }
          & > p {
            font-size: 12px;
            text-transform: uppercase;
            color: #c1bebe;
          }
          img {
            margin: 0;
            height: fit-content;
          }
        }
      }
    }

    .transactions {
      cursor: pointer;
      transition: 0.3s;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;
      min-height: 35px;
      &:hover {
        background-color: rgb(243, 243, 243);
      }
      p {
        padding: 0;
        margin: 0;
        color: #8d8d8d;
      }
    }
  }
}

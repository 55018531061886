.socialLinks {
  display: grid;
  grid-template-columns: repeat(2, 40px);
  row-gap: 15px;

  svg {
    display: inline-block;
    height: 16px;
    width: 27px;
    fill: #6b6b6b;
    &:hover {
      fill: #0619f5;
    }
  }
}

.socialLinks.mobile {
  display: flex;
  justify-content: space-evenly;
}

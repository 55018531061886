@font-face {
  font-family: "ZametkaParletter";
  src: url("../../../Resources/Fonts/zametka-parletter.ttf") format("truetype");
}

.main {
  p,
  button {
    font-family: "ZametkaParletter", sans-serif;
    line-height: 1.5;
  }
  .wrapper {
    position: relative;
  }
  .content {
    position: absolute;
    background-color: #f0f0f0;
    width: 300px;
    padding: 25px;
    border-radius: 57px 20px 20px 20px;
    text-align: right;
    top: 0;
    right: -213px;
    margin-top: 21px;
    span[class^="highlight-"] {
      color: #050096;
    }
    span[class="highlight-2"] {
      background-color: #d9d9d9;
      padding: 6px 6px 3px;
      border-radius: 13px;
    }
  }
  .start {
    bottom: 60px;
    padding: 35px 20px 24px;
    border-radius: 20px 76px 20px 20px;
    left: 240px;
  }

  .cancel {
    bottom: 17px;
    padding: 8px 36px 0px;
    border-radius: 40px 0 0 0;
    right: -213px;
  }
  .start, .cancel {
    position: absolute;
    color: #050096;
    background-color: #d9d9d9;
    width: auto;
    font-size: 24px;
    &:hover {
      background-color: #a5a5a5;
    }
  }
}

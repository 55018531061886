.container {
  position: absolute;
  z-index: 1;
  &.active {
    background-color: #fff;
    height: 136px;
    width: 100%;
  }
  .filters {
    position: absolute;
    bottom: 24px;
    left: 32px;
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 16px;
  }
}

.searchContainer {
  position: absolute;
  top: 16px;
  left: 32px;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(20px);
  z-index: 1;
  width: 560px;
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  &.active {
    background-color: #e6e6e6;
  }
  .buttons {
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 16px;
  }
  .input {
    width: 100%;
    margin-right: 16px;
  }
  .inputBase {
    padding-bottom: 13px;
  }
}

.resultContainer {
  position: absolute;
  top: 136px;
  height: calc(100% - 136px);
  width: 592px;
  background: #e6e6e6;
  z-index: 2;
  padding: 42px 32px 32px;
  overflow-y: scroll;
  .closeIcon {
    position: sticky;
    float: right;
    top: 0;
    img {
      margin-right: -10px;
      margin-top: -20px;
    }
  }
  .items {
    display: grid;
    grid-auto-flow: row;
    margin-top: 32px;
  }
}

.loader {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@media only screen and (max-width: 600px) {
  .container {
    width: 100%;
  }
  .searchContainer {
    top: 0;
    left: 0;
    width: 100%;
  }
  .resultContainer {
    height: calc(100% - 102px);
    width: 100%;
  }

  .resultContainer {
    top: 190px;
  }

  .container {
    &.active {
      height: 190px;
    }
    .filters {
      display: block;
      bottom: 5px;
      & > div {
        margin-top: 5px;
      }
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  padding: 24px;
  min-width: 280px;
  .title {
    font-weight: bold !important;
    margin-bottom: 28px !important;
  }
  .buttons {
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 24px;
    justify-content: flex-end;
    margin-top: 50px;
  }
  .feeLabel {
    margin-bottom: 16px !important;
  }
}

.newClusterDialog {
  margin-top: 8px;
}

@media only screen and (max-width: 600px) {
  .dialogWindow > div {
    align-items: flex-start;
  }
  .dialogWindow > div > div {
    width: 100%;
  }
  .dialogButtons {
    button {
      min-width: 48%;
    }
  }
}

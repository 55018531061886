.container {
  display: flex;
  padding: 20px 40px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  &.primary {
    background-color: #DAA352;
    border-bottom: 0;
    color: #fff !important;
    .address {
      font-weight: bold !important;
    }
  }
  &.my {
    position: -webkit-sticky;
    position: sticky;
    background-color: rgba(0, 0, 0, 0.9);
    color: #fff !important;
    bottom: 0;
    .address {
      font-weight: bold !important;
    }
  }
  &.my.userCensored {
    background-color: #BF1717;
    padding-bottom: 14px;
    padding-top: 17px;
  }
  .index {
    font-weight: bold !important;
    min-width: 35px;
  }
  .pixels, .unit {
    margin-top: 8px;
    font-weight: 900 !important;
  }
  .unit span {
    font-size: .7em;
  }
  .value {
    display: flex;
    align-items: baseline;
  }

  .avatar {
    background-color: #fff;
    width: 80px;
    height: 80px;
    &.upload {
      color: #000;
      margin-left: 20px;
      text-transform: uppercase;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 14px;
      cursor: pointer;
      span {
        font-weight: 700;
      }
    }
    &.cover {
      margin-left: 20px;
      cursor: default;
      background-size: cover;
      background-position: 50%;
    }
    &.myAvatar {
      cursor: pointer
    }
  }
  .right {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    .top {
      display: grid;
      grid-auto-flow: column;
      grid-column-gap: 16px;
      grid-row-gap: 7px;
      justify-content: flex-start;
      align-items: center;
    }
  }
}

.uploadInput {
  display: none;
}

p.alert {
  margin: 0 0 4px 0;
}

@media only screen and (max-width: 600px) {
  .container {
    padding: 9px;
    .right {
      .top {
        grid-auto-flow: row;
        span.address {
          font-size: 0.6rem;
        }
      }
    }
    .avatar {
      &.cover, &.upload {
        margin-left: 10px;
      }
    }
  }
}

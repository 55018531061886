.main {
    .desktop {
        // margin-top: 20px;
        margin-bottom: 43px;
        display: grid;
        column-gap: 50px;
        grid-template-columns: 1fr 1fr 1fr;
        // min-width: 500px;
        // max-width: 700px;
        width: 100%;
        background-color: #fff;
        align-items: center;
        padding: 12px 50px;
        align-items: start;
    }
    .mobile {
        display: none;
    }
}

@media only screen and (max-width: 991px) {
    .main {
      grid-template-columns: 1fr;
      .desktop {
        display: none
      }
      .mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        padding: 20px 10px 15px;
        margin: 10px 0 20px 0;
      }
    }
  }
  
  @media only screen and (max-width: 350px) {
    .bastBtnGroup {
      margin-bottom: 10px;
      & > div {
        button {
          width: 100px;
        }
      }
    }
  }
.toolTipIcon {
    position: relative;
    top: 5px;
    left: 3px;
    margin-top: -5px;
  }

  .mobileSection {
    display: inline;
    text-transform: none;
    & > div > div {
      min-width: 200px;
    }
  }